
import Vue from 'vue'
import Component from 'vue-class-component'
import { Mutation } from 'vuex-class'
import SheetHandler, { InvalidSheetHeader } from '@/lib/SheetHandler'
import TransferSheetHandler from '@/lib/TransferSheetHandler'
import MemberInfo from '@/models/MemberInfo'
import { WaitRemittanceGroup } from '@/gateway/remittance/WaitRemittanceGroup'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component
export default class TransferUpload extends Vue {
  @Mutation setApplyTransfers: any
  @Mutation setValidatedTransfers: any
  @Mutation setManualValidatedTransfers: any
  @Mutation disableLoading: any

  sheetHandler: SheetHandler = new TransferSheetHandler()

  showAlert(message = ''): void {
    if (!message) return
    this.$alert(message, {
      showCancelButton: true,
      showConfirmButton: false,
      dangerouslyUseHTMLString: true,
      cancelButtonText: `${this.$t('commons.close')}`,
      center: true
    })
    this.disableLoading()
  }

  checkInValidFormat(): boolean {
    const isEmptyParsedSheet = !Object.keys(this.sheetHandler.sheetJson).length
    const transferValidHeaders = this.sheetHandler.sheetValidHeaders
    const invalidSheetHeader: InvalidSheetHeader =
      this.sheetHandler.getInvalidSheetHeader(transferValidHeaders)
    const isInvalidParsedSheet = !!invalidSheetHeader.sheetName.length
    let message = ''
    isEmptyParsedSheet && (message = `${this.$t('notification.errors.excel_wrong')}`)
    isInvalidParsedSheet &&
      (message = `<p>올바르지 않은 포맷입니다.</p>
        <p>헤더명을 확인 해 주세요<br>
        시트 이름 : ${invalidSheetHeader.sheetName}<br>
        헤더 이름 : ${invalidSheetHeader.headerName}<br>
        파일을 다시한번 확인 해 주세요</p>`)
    this.showAlert(message)
    return isInvalidParsedSheet || isEmptyParsedSheet
  }

  addGAEvent(fileName: string) {
    this.$gtm.trackEvent({
      event: 'button_click',
      category: 'transfer',
      label: 'upload-excel',
      value: fileName || 'no-file-name'
    })
  }

  async uploadSheet(files: any): Promise<void> {
    const invalidFile = !(files && files.raw)
    if (invalidFile) return
    try {
      const file = files.raw
      await this.sheetHandler.sheetToJson(file, { range: 0 })
      this.addGAEvent(file.name)
    } catch (error) {
      this.$message({ message: error.message, type: 'error' })
    }
    if (this.checkInValidFormat()) return
    this.setApplyTransfers(this.sheetHandler.sheetJson)
    this.$router.push('invalidApply')
  }

  goToHistoryPage() {
    this.$message({
      message: `${this.$t('notification.errors.excel_wrong')}`,
      type: 'warning'
    })
    this.$router.replace('/history')
  }

  async hasWaitRemittanceGroup(): Promise<boolean> {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.push('/signIn')
      return false
    }
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const params = { corp_id: memberInfo.corp_id }
    const hasWaitingRemittance = await WaitRemittanceGroup.getInstance().request(params)
    return hasWaitingRemittance
  }

  async created() {
    if (await this.hasWaitRemittanceGroup()) return this.goToHistoryPage()
    this.setManualValidatedTransfers({})
    this.setValidatedTransfers({})
  }
}
