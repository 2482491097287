import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'

export interface WaitRemittanceGroup extends IApiRequest {
  getInstance (): WaitRemittanceGroup;

  request (params: { corp_id: string }): Promise<boolean>;
}

export class WaitRemittanceGroup implements WaitRemittanceGroup {
  private static instance: WaitRemittanceGroup

  private constructor () {}
  public static getInstance (): WaitRemittanceGroup {
    if (!this.instance) this.instance = new WaitRemittanceGroup()
    return this.instance
  }

  async request (params: { corp_id: string }): Promise<boolean> {
    const requestOption: RequestOption = {
      url: 'remittance/group/waiting',
      method: 'get',
      params
    }
    const response = await sentbizApi.request(requestOption)
    return !!response.data?.wait_group_count
  }
}
